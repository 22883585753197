<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Добро пожаловать на&nbsp;витрину сертификатов Giftery!</h1>
        </div>
      </div>

      <hr>

      <h3>Как это работает?</h3>
      <p>В&nbsp;рамках программы лояльности Sincura на&nbsp;Ваш бонусный счет начисляются баллы, которые можно обменять на&nbsp;электронные подарочные сертификаты.</p>
      <p>Вы перейдете в&nbsp;каталог, где сможете выбрать один или&nbsp;несколько сертификатов известных брендах в&nbsp;самых разных категориях.</p>
      <p>Выберите сертификат(ы) и&nbsp;введите контактные данные.</p>
      <p>Электронные сертификаты будут моментально доставлены на&nbsp;указанный Вами e-mail.</p>

      <hr>

      <h3>Возникли вопросы?</h3>
      <p>Свяжитесь с&nbsp;технической службой поддержки Giftery <b>{{ supportWorkingHours }}</b></p>
      <p>Служба заботы о клиентах: <b><a :href="'tel:' + supportPhone">{{ supportPhone }}</a></b><br>
      Email: <b><a :href="'mailto:' + supportEmail">{{ supportEmail }}</a></b></p>
      <p>Также Вы можете обращаться в&nbsp;консьерж службу Sincura&nbsp;24/7<br>
      Тел: <b><a href="tel:+74956264042">+7&nbsp;495&nbsp;626&nbsp;4042</a></b><br>
      E-mail: <b><a href="mailto:request@sincura.ru">request@sincura.ru</a></b></p>
      <br>
      <router-link class="button button--large button--rounded" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import { supportPhones, supportEmails, workingHours } from 'base/modules/constants'
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  computed: {
    ...mapGetters({
      region: 'main/region',
    }),
    supportPhone () {
      return supportPhones[this.region]
    },
    supportEmail () {
      return supportEmails[this.region]
    },
    supportWorkingHours () {
      return workingHours[this.region]
    }
  },
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 80%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 32.3%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 32.3%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }

  .text-danger {
    color: #c00;
    font-weight: bold;
  }
</style>
